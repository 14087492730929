<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: orgPath }">{{
          breadcrumbName
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>代帐报税</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container">
      <div class="box">
        <div class="head_check">
          <div class="left_title">定制代账方案</div>
          <div class="check_box">
            <div class="check_title">根据公司年营业额灵活定制：</div>
            <el-radio v-model="radio" label="1">无票据往来</el-radio>
            <el-radio v-model="radio" label="2">0-100万</el-radio>
            <el-radio v-model="radio" label="3">100-500万</el-radio>
            <el-radio v-model="radio" label="4">500万以上</el-radio>
          </div>
        </div>
        <div class="search_box">
          <el-form
            ref="formInline"
            :inline="true"
            :rules="rules"
            :model="formInline"
            class="demo-form-inline"
          >
            <el-form-item>
              <el-input
                v-model="formInline.address"
                placeholder="地址"
                readonly="readonly"
                prefix-icon="el-icon-location-outline"
              ></el-input>
            </el-form-item>
            <el-form-item class="mobile" prop="reservePhone">
              <el-input
                class="mobile_input"
                v-model="formInline.reservePhone"
                maxlength="11"
                placeholder="请输入手机号码"
                prefix-icon="el-icon-mobile-phone"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit('formInline')"
                >免费定制</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="box" style="padding-top: 0">
        <div class="title hot_title">爆款推荐</div>
        <div class="hot_list">
          <div class="hot_item hot_item_bg1">
            <span>经营账外包</span>
            <span>业务、财务一体化</span>
            <span>有效指导经营决策</span>
            <div class="hot_btn" @click="handleApply">立即咨询</div>
          </div>
          <div class="hot_item hot_item_bg2">
            <span>旧账梳理</span>
            <span>梳理旧账制定规范</span>
            <span>专业会计贴心服务</span>
            <div class="hot_btn" @click="handleApply">立即咨询</div>
          </div>
          <div class="hot_item hot_item_bg1">
            <span>小规模代账</span>
            <span>品牌保障不满包换</span>
            <span>买2年送1年</span>
            <div class="hot_btn" @click="handleApply">立即咨询</div>
          </div>
          <div class="hot_item hot_item_bg3">
            <span>一般纳税人代账</span>
            <span>品牌保障不满包换</span>
            <span>专业会计专业代账</span>
            <div class="hot_btn" @click="handleApply">立即咨询</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="container container1">
      <div class="box">
        <div class="top">
          <div class="title">代记账须知</div>
          <div class="stitle">
            <div class="left_line">
              <img
                src="../../assets/img/finance_1/detail/left_line.png"
                alt=""
              />
            </div>
            <span>政策解读</span>
            <div class="right_line">
              <img
                src="../../assets/img/finance_1/detail/right_line.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="irregular_box">
          <div class="irregular_top">
            <div class="left_irregular">
              <span class="title">申报条件</span>
              <span>服务对象需求：</span>
              <span>（一）设立登记在本省行政区域内的；</span>
              <span
                >（二）取得法人资格，依法独立享有民事权利和承担民事法律责任的；</span
              >
              <span
                >（三）符合《关于印发中小企业划型标准规定的通知》（工信部联企业〔2011〕300号）规定的中小微企业标准的；</span
              >
              <span
                >（四）已开展正常生产经营活动，且已经实现收入的；(设立登记日至申请日三个月内的中小微企业，作出书面承诺)</span
              >
              <span
                >（五）依法办理纳税申报、缴纳社会保险，具有健全规章制度且运行良好的；</span
              >
              <span>（六）已依法取得相关行业行政许可或行业资质的。</span>
            </div>
            <div class="right_irregular">
              <span class="title">不符合记账情形</span>
              <span
                >有下列情形的中小微企业，不纳入服务范围或中止政府为其购买服务事项：</span
              >
              <span
                >1、被列入“信用中国”网站中失信被执行人、重大税收违法案件当事人名单的；</span
              >
              <span
                >2、被列入“中国政府采购网”政府采购严重违法失信行为记录名单的；</span
              >
              <span
                >3、被列入“国家企业信用公示系统”中经营异常名录、严重违法失信名单的；违反其他法律法规等情形的。</span
              >
            </div>
          </div>
          <ul class="irregular_list">
            <li class="irregular_item">
              <span class="title">服务内容</span>
              <span
                >审核原始凭证、填制记账凭证、登记会计账簿、编制并对外提供财务会计报告，向税务机关提供税务资料。</span
              >
              <span
                >服务对象接受会计服务机构提供上述范围以外的其他服务，不属于购买代理记账服务范围。</span
              >
            </li>
            <li class="irregular_item">
              <span class="title">申报材料清单</span>
              <span>（一）中小微企业申请财政购买代理记账服务申请表；</span>
              <span>（二）法人身份证、营业执照及其他相关资质复印件；</span>
              <span
                >（三）近三个月会计报表、纳税申报表、完税证明及社保缴纳情况表；</span
              >
              <span
                >（四）承诺书。(设立登记日至申请日三个月内的中小微企业提供)</span
              >
            </li>
            <li class="irregular_item">
              <span class="title">申报流程</span>
              <span>（一）中小微企业申请财政购买代理记账服务申请表；</span>
              <span>（二）法人身份证、营业执照及其他相关资质复印件；</span>
              <span
                >（三）近三个月会计报表、纳税申报表、完税证明及社保缴纳情况表</span
              >
              <span
                >（四）承诺书。(设立登记日至申请日三个月内的中小微企业提供)</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div> -->

    <div class="container container_bg">
      <div class="box">
        <div class="service_process">
          <div class="title">服务流程</div>
        </div>
        <div class="service_process_list">
          <div class="service_process_item">
            <div class="process_num">1</div>
            <div class="process_content">
              <div class="process_title">沟通咨询</div>
              <div class="process_btn" @click="handleApply">立即咨询</div>
            </div>
          </div>
          <div class="right_arrow">
            <img src="" alt="" />
          </div>
          <div class="service_process_item">
            <div class="process_num">2</div>
            <div class="process_content">
              <div class="process_title">数电通票据归集</div>
              <div class="process_btn" @click="handleApply">立即咨询</div>
            </div>
          </div>
          <div class="right_arrow">
            <img src="" alt="" />
          </div>
          <div class="service_process_item">
            <div class="process_num">3</div>
            <div class="process_content">
              <div class="process_title">按时纳税申报</div>
              <div class="process_btn" @click="handleApply">立即咨询</div>
            </div>
          </div>
          <div class="right_arrow">
            <img src="" alt="" />
          </div>
          <div class="service_process_item">
            <div class="process_num">4</div>
            <div class="process_content">
              <div class="process_title">实时监控财务状况</div>
              <div class="process_btn" @click="handleApply">立即咨询</div>
            </div>
          </div>
        </div>
        <div class="djz_box">
          <div class="left_list">
            <div class="title">
              <span>代记账服务</span>
              <span>提供20余项代账服务</span>
            </div>
            <div class="djz_list_box">
              <ul>
                <li>01 协助签订三方协议</li>
                <li>02 税务核定</li>
                <li>03 购买税控装备</li>
                <li>04 发票增量、增版</li>
                <li>05 代交税控盘或申报服务费用</li>
                <li>06 办理税控盘解锁</li>
                <li>07 办理发票认证工作</li>
                <li>08 税务局代开发票</li>
              </ul>
              <ul>
                <li>09 代缴认证系统服务费</li>
                <li>10 办理国地稅证明</li>
                <li>11 国地税报道</li>
                <li>12 编制企业财务报表</li>
                <li>13 年度所得税汇算</li>
                <li>14 办理外经证</li>
                <li>15 协助缴纳税款</li>
                <li>16 协助三方协议税务备案</li>
              </ul>
              <ul>
                <li>17 协助银行网上对账</li>
                <li>18 打印银行对账及回单</li>
                <li>19 协助银行年检</li>
                <li>20 协助银行解非/解冻</li>
                <li>21 协助网银审核</li>
                <li>22 签三方协议（银行）</li>
                <li>23 远程协助代开发票/抄税/清卡</li>
                <li>24 外勤上门服务</li>
              </ul>
            </div>
          </div>
          <div class="right_img">
            <img src="../../assets/img/finance_1/detail/right_img.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="box">
        <div class="top">
          <div class="title">代记账方案</div>
          <div class="stitle">
            <div class="left_line">
              <img
                src="../../assets/img/finance_1/detail/left_line.png"
                alt=""
              />
            </div>
            <span>按照您的公司类型选择代账方案</span>
            <div class="right_line">
              <img
                src="../../assets/img/finance_1/detail/right_line.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="programme_box">
          <ul class="programme_list">
            <li class="programme_item">
              <div class="icon">
                <img src="../../assets/img/finance_1/detail/icon1.png" alt="" />
              </div>
              <span>内资小规模及一般纳税人企业</span>
            </li>
            <li class="programme_item">
              <div class="icon">
                <img src="../../assets/img/finance_1/detail/icon2.png" alt="" />
              </div>
              <span>外资小规模及一般纳税人企业</span>
            </li>
            <li class="programme_item">
              <div class="icon">
                <img src="../../assets/img/finance_1/detail/icon3.png" alt="" />
              </div>
              <span>进出口企业</span>
            </li>
            <li class="programme_item">
              <div class="icon">
                <img src="../../assets/img/finance_1/detail/icon4.png" alt="" />
              </div>
              <span>高新企业</span>
            </li>
            <li class="programme_item">
              <div class="icon">
                <img src="../../assets/img/finance_1/detail/icon5.png" alt="" />
              </div>
              <span>企业代表处</span>
            </li>
            <li class="programme_item">
              <div class="icon">
                <img src="../../assets/img/finance_1/detail/icon6.png" alt="" />
              </div>
              <span>制造企业</span>
            </li>
            <li class="programme_item">
              <div class="icon">
                <img src="../../assets/img/finance_1/detail/icon7.png" alt="" />
              </div>
              <span>特殊行业企业</span>
            </li>
            <li class="programme_item">
              <div class="icon">
                <img src="../../assets/img/finance_1/detail/icon8.png" alt="" />
              </div>
              <span>全国连锁企业</span>
            </li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container container_bg1">
      <div class="box">
        <div class="title">代账服务</div>
        <div class="content_box">
          <span
            >专业的代记账公司，拥有丰富的财务经验和卓越的会计团队，致力于提供全方位的财务咨询服务，为企业的财务安全保驾护航。</span
          >
          <span>产品特点：</span>
          <span
            >1.高效便捷：我们将为您处理所有繁琐的财务问题，让您专注于您的核心业务。</span
          >
          <span
            >2.严格保密：我们深知财务信息的敏感性，因此我们承诺将严格保密您的信息。</span
          >
          <span
            >3.专业可靠：我们的会计团队拥有多年的经验，可以为您提供高质量的财务咨询服务。</span
          >
          <span
            >4.定制服务：我们根据您的需求提供定制化的服务，以满足您的特定需求。</span
          >
          <span
            >5.高效沟通：我们拥有高效的沟通机制，确保您随时了解财务状况，避免任何潜在的风险。</span
          >
          <span
            >6.价格合理：我们的价格合理透明，让您在享受专业服务的同时，不会感到经济负担。</span
          >
        </div>
      </div>
    </div>

    <div class="btn" @click="handleApply">立即体验</div>

    <!-- 一键办理弹出框 -->
    <el-dialog
      title="预留信息"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :before-close="dialogColse"
    >
      <div class="dialog_head">
        <span>我们的客户经理将在1个工作日内与您联系！</span>
      </div>
      <div class="register_phone">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="10px"
          class="demo-ruleForm"
        >
          <el-form-item prop="reserveName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.reserveName"
              placeholder="请输入称呼昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reservePhone">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="ruleForm.reservePhone"
              maxlength="11"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reserveEntName">
            <el-input
              prefix-icon="el-icon-office-building"
              v-model="ruleForm.reserveEntName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              v-model="ruleForm.remark"
              placeholder="备注......(200字以内)"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交预约</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>

    <el-dialog :visible.sync="dialogSuccess" width="25%" center>
      <div class="success_icon">
        <img src="../../assets/img/success.png" alt="" />
      </div>
      <div class="success_title">提交成功</div>
      <div class="success_content">
        <p>随后工作人员将与您联系，请保持电话畅通。</p>
        <p>咨询热线：<span>400-666-3369</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addReserve } from "../../api/reserve";

export default {
  components: {},
  props: {},
  data() {
    return {
      org: "",
      orgPath: "",
      breadcrumbName: "",
      radio: "1",
      formInline: {
        address: "太原",
        reservePhone: "",
        acctName: "",
        remark: "代账方案",
      },
      dialogSuccess: false,
      dialogVisible: false,
      registerPhone: "",
      ruleForm: {
        reserveName: "",
        reservePhone: "",
        reserveEntName: "",
        remark: "",
      },
      rules: {
        reserveName: [
          { required: true, message: "请输入预约人名称", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        reservePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formInline.reserveType = "02";
          addReserve(this.formInline).then((response) => {
            this.dialogSuccess = true;
          });
        }
      });
    },
    // 体验信息提交
    handleApply() {
      this.dialogVisible = true;
    },
    // subInfo() {
    //   this.$router.push({
    //     path: "/info",
    //     query: { org: "sz" },
    //   });
    // },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.reserveType = "02";
          addReserve(this.ruleForm).then((response) => {
            // if (response.code == 200) {
            //   // this.$modal.msgSuccess("意见办理注册预约成功");
            //   this.dialogSuccess = true;
            // }
            this.dialogSuccess = true;
            this.dialogVisible = false;
          });
        }
      });
    },
    dialogColse() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },

    dialogSuccessColse() {},
  },
  created() {
    this.org = this.$route.query.org;
    if (this.org == "home") {
      this.orgPath = "/";
      this.breadcrumbName = "首页";
    } else {
      this.orgPath = "/finance_1";
      this.breadcrumbName = "代理记账服务";
    }
  },
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}
.container1 {
  padding-bottom: 80px;
}

.container_bg {
  background: #055afe;
}

.container_bg .box {
  position: relative;
}

.container_bg1 {
  background: #f5f5f5;
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
}

.line {
  display: block;
  width: 40px;
  height: 1px;
  background: #055afe;
  margin-top: 10px;
}

.stitle {
  display: flex;
  align-items: center;
  margin-top: 6px;
}
.stitle img {
  display: block;
  width: 100%;
}

.stitle span {
  font-size: 18px;
  color: #666;
  display: block;
  margin: 0 10px;
}

.left_line {
  width: 22px;
  height: 1px;
}

.irregular_box {
  width: 100%;
}

.irregular_top {
  height: 230px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.left_irregular {
  width: 646px;
  background: #f4f8ff;
  border-radius: 10px;
  padding: 16px;
}

.right_irregular {
  width: 537px;
  background: #f4f8ff;
  border-radius: 10px;
  padding: 16px;
}

.irregular_top .title {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}

.irregular_top span {
  display: block;
  font-size: 12px;
  margin-top: 6px;
}

.irregular_list {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.irregular_item {
  width: 32.5%;
  border-radius: 10px;
  padding: 16px;
  background: #f4f8ff;
}

.irregular_item span {
  display: block;
  font-size: 12px;
  margin-top: 6px;
  letter-spacing: -0.5px;
}

.irregular_item span.title {
  font-size: 14px;
  color: #333;
  font-weight: 600;
}

.box {
  width: 1200px;
  padding: 30px 0;
  margin: 0 auto;
}

.img_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.img_box span {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}

.img_box img {
  display: block;
  width: 100%;
}

.simg_box {
  display: flex;
  justify-content: center;
}

.img_box1 {
  width: 50%;
}

.img_box1 img {
  width: 200px;
  height: 350px;
}

.box p {
  font-size: 18px;
  color: #333333;
  line-height: 34px;
  padding: 40px 0;
  text-align: justify;
}

.box p:first-child {
  padding-top: 0;
}

.btn {
  width: 130px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  background: #055afe;
  color: #fff;
  margin: 40px auto;
  cursor: pointer;
}

.left_list {
}

.left_list .title {
  /* display: flex; */
}

.left_list .title span {
  display: block;
  color: #fff;
}

.left_list .title span:first-child {
  font-size: 24px;
  font-weight: 600;
}

.left_list .title span:last-child {
  font-size: 18px;
  margin-top: 6px;
  font-weight: normal;
}

.djz_list_box {
  width: 812px;
  display: flex;
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  margin-top: 20px;
}

.djz_list_box ul {
  width: 33.33%;
}

.djz_list_box ul li {
  width: 100%;
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

.right_img {
  /* position: absolute;
  right: 0;
  top: -45px; */
  width: 362px;
  height: 448px;
  margin-left: 25px;
}

.right_img img {
  display: block;
  width: 100%;
  height: 100%;
}

.programme_box {
}

.programme_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.programme_item {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 60px;
}

.programme_item .icon {
  width: 60px;
  height: 60px;
}

.programme_item .icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.programme_item span {
  display: block;
  width: 200px;
  font-size: 14px;
  color: #333;
}

.container_bg1 .box .title {
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: #333333;
}

.content_box {
  padding-left: 40px;
}

.content_box span {
  display: block;
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}

.content_box span:first-child {
  margin-top: 30px;
}

.head_check {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left_title {
  font-size: 24px;
  font-weight: bold;
}
.check_box {
  display: flex;
  align-items: center;
}
.check_title {
  font-size: 18px;
  color: #949494;
}

.search_box {
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}

.mobile {
  width: 70%;
}

.mobile >>> .el-form-item__content {
  width: 100%;
}

.mobile_input {
  display: block;
  width: 100%;
}

.search_box .el-button {
  background: #195bf8;
}
.el-form {
  text-align: center;
}

.hot_title {
  text-align: center;
}

.hot_list {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.hot_item {
  position: relative;
  width: 266px;
  height: 166px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.hot_item_bg1 {
  background-image: url("../../assets/img/finance_1/detail/hot1.png");
}

.hot_item_bg2 {
  background-image: url("../../assets/img/finance_1/detail/hot2.png");
}

.hot_item_bg3 {
  background-image: url("../../assets/img/finance_1/detail/hot3.png");
}

/* .hot_item_bg4 {
  background-image: url("../../assets/img/finance_1/detail/hot4.png");
} */

.hot_item span {
  display: block;
  padding: 0 14px;
}

.hot_item span:nth-child(1) {
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  padding-top: 14px;
}

.hot_item span:nth-child(2) {
  font-size: 18px;
  color: #949494;
  margin-top: 7px;
  margin-bottom: 14px;
}
.hot_item span:nth-child(3) {
  font-size: 18px;
  color: #195bf8;
  font-weight: bold;
}

.hot_item:hover .hot_btn {
  background: #195bf8;
  color: #fff;
  border-radius: 0 0 10px 10px;
}

.hot_btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 45px;
  line-height: 45px;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  text-align: center;
  padding: 0 14px;
  cursor: pointer;
}

.service_process {
  margin-bottom: 50px;
}

.service_process .title {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
}

.service_process_list {
  display: flex;
  align-items: center;
  margin-bottom: 90px;
}

.service_process_item {
  display: flex;
  align-items: center;
}

.process_num {
  font-size: 100px;
  font-weight: bold;
  color: #ffffff;
  margin-right: 10px;
}

.process_title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 10px;
}

.process_btn {
  width: 121px;
  height: 35px;
  background: #ffffff;
  border-radius: 18px;
  font-size: 18px;
  font-weight: bold;
  color: #195bf8;
  line-height: 35px;
  text-align: center;
}

.right_arrow {
  width: 50px;
  height: 50px;
  margin: 0 24px;
}

.right_arrow img {
  display: block;
  width: 100%;
}

.djz_box {
  display: flex;
  align-items: flex-end;
}

.dialog_head {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #055afe;
  margin-bottom: 20px;
}

.success_icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.success_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.success_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0;
}

.success_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #919191;
}
.success_content p span {
  color: #055afe;
}
</style>
